import { validate } from 'gerador-validador-cpf';
class Validators {
    validateEmail(email) {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }
    validateName(name) {
        return /^[A-z]+([- ][A-z]+)*$/.test(name) && name != null;
    }
    validateTelephone(telephone) {
        return telephone != null && /^\(\d{2}\) \d{5}-\d{4}$/.test(telephone);
    }
    validateIsNotEmpty(item) {
        return item != null && item != undefined && item != '';
    }
    validateCPF(cpf) {
        return validate(cpf);
    }
    isValidOrInvalid(valid) {
        return valid ? 'is-valid' : 'is-invalid';
    }
    validateCognitoPassword(password) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/.test(
            password
        );
    }
    setMessageError(isValid, msg) {
        return !isValid ? msg : '';
    }
    getEstadosBrasileirosPelaSigla(state) {
        if (state.toUpperCase() === 'AC') return 'Acre';
        if (state.toUpperCase() === 'AL') return 'Alagoas';
        if (state.toUpperCase() === 'AP') return 'Amapá';
        if (state.toUpperCase() === 'AM') return 'Amazonas';
        if (state.toUpperCase() === 'BA') return 'Bahia';
        if (state.toUpperCase() === 'CE') return 'Ceará';
        if (state.toUpperCase() === 'DF') return 'Distrito Federal';
        if (state.toUpperCase() === 'ES') return 'Espírito Santo';
        if (state.toUpperCase() === 'GO') return 'Goiás';
        if (state.toUpperCase() === 'MA') return 'Maranhão';
        if (state.toUpperCase() === 'MT') return 'Mato Grosso';
        if (state.toUpperCase() === 'MS') return 'Mato Grosso do Sul';
        if (state.toUpperCase() === 'MG') return 'Minas Gerais';
        if (state.toUpperCase() === 'PA') return 'Pará';
        if (state.toUpperCase() === 'PB') return 'Paraíba';
        if (state.toUpperCase() === 'PR') return 'Paraná';
        if (state.toUpperCase() === 'PE') return 'Pernambuco';
        if (state.toUpperCase() === 'PI') return 'Piauí';
        if (state.toUpperCase() === 'RJ') return 'Rio de Janeiro';
        if (state.toUpperCase() === 'RN') return 'Rio Grande do Norte';
        if (state.toUpperCase() === 'RS') return 'Rio Grande do Sul';
        if (state.toUpperCase() === 'RO') return 'Rondônia';
        if (state.toUpperCase() === 'RR') return 'Roraima';
        if (state.toUpperCase() === 'SC') return 'Santa Catarina';
        if (state.toUpperCase() === 'SP') return 'São Paulo';
        if (state.toUpperCase() === 'SE') return 'Sergipe';
        if (state.toUpperCase() === 'TO') return 'Tocantins';
    }
}

export default new Validators();
