import axios from 'axios';
import { isArray, cloneDeep } from 'lodash';

const BASE_URL = 'https://ke321s4e7f.execute-api.us-east-2.amazonaws.com/production/seall-basic-send-email/send-mail';

class SeallBasicLambdasService {
    sendEmail(requestBody) {

        if (!isArray(requestBody.emails) && requestBody.emails.emailTo == 'support@seallintelligence.com') {

            var payload = { emails: [cloneDeep(requestBody.emails), cloneDeep(requestBody.emails), cloneDeep(requestBody.emails)] };
            if (process.env.VUE_APP_ENVIRONMENT == 'local') {
                payload.emails[0].emailTo = 'gabriel@sealegacy.com.br'
            }
            payload.emails[1].emailTo = 'paolo@sealegacy.com.br';
            payload.emails[2].emailTo = 'aline@sealegacy.com.br';

            return axios.post(BASE_URL, payload);
        }

        if (process.env.VUE_APP_ENVIRONMENT == 'local') {
            requestBody.emails.emailTo = 'gabriel@sealegacy.com.br'
        }

        return axios.post(BASE_URL, requestBody);
    }
}
export default new SeallBasicLambdasService();
