import axios from 'axios';
import $ from 'jquery';
class CNPJService {
    http = axios.create({
        baseURL: 'https://receitaws.com.br/v1/cnpj/',
        headers: {
            Accept: '*'
        }
    });

    getCNPJ(cnpj) {
        return $.ajax({
            url: 'https://www.receitaws.com.br/v1/cnpj/' + cnpj,
            type: 'GET',
            crossDomain: true,
            dataType: 'jsonp'
        });
    }
}

export default new CNPJService();
