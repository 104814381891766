<template>

  <GDialog v-model="openDialogVerificationCode" max-width="500">
    <div class="g-dialog wrapper">
      <div class="content">
        <div class="title">Atenção</div>

        <p>
          Um código de verificação será enviado para o e-mail da OSC informado
        </p>
      </div>

      <div class="actions">
        <button class="btn btn-outline-gray" @click="openDialogVerificationCode = false">
          Fechar
        </button>
      </div>
    </div>
  </GDialog>

  <div class="row main">
    <loading :active="loading" :is-full-page="true"></loading>
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
      rel="stylesheet" />
    <div class="col-xl-12 organization">
      <div class="box bg-white">
        <div class="logos">
          <div class="img">
            <img src="../images/mais-logo.svg" alt="Mapa do Impacto Social" />
            <img src="../images/logo-seall.png" alt="Seall Intelligence" />
          </div>
          <div class="duvidas">
            <p>Como podemos te ajudar?</p>
            <a href="/mais/fale-conosco" class="mx-3 btn btn-fale-conosco">
              Fale Conosco
            </a>
          </div>
        </div>

        <h2 class="m-0">{{ $t("mais.header.title") }}</h2>

        <form class="w-100" @change="validateForm()">
          <ul class="nav nav-tabs justify-content-center">
            <li class="nav-item cursor-pointer">
              <span class="nav-link d-flex align-items-center" :class="{ active: tab.current_tab === 1 }"
                @click.prevent="tab.current_tab = 1">
                {{ $t("mais.tab.title_1") }}
                <i class="material-icons" v-tooltip="tooltips.informacaoSolicitante">
                  info
                </i>
              </span>
            </li>
            <li class="nav-item cursor-pointer">
              <span class="nav-link d-flex align-items-center" :class="{
                active: tab.current_tab === 2,
                disabled: !tab.first_item_completed,
              }" @click.prevent="tab.current_tab = 2">
                {{ $t("mais.tab.title_2") }}
                <i class="material-icons" v-tooltip="tooltips.informacaoOSC">
                  info
                </i>
              </span>
            </li>
          </ul>
          <div class="form">
            <!-- PRIMEIRA ETAPA -->
            <section class="mt-3" v-if="tab.current_tab === 1">
              <div class="row">
                <!-- CPF DO CADASTRADOR -->
                <div class="col-lg-12 col-xl-6 mb-3">
                  <label class="form-label required">
                    {{ $t("mais.form.createdByCPF.field") }}
                  </label>
                  <input v-model="maisOrg.createdByCPF" type="text" class="form-control"
                    :class="formFieldStatus.createdByCPF" v-maska="'###.###.###-##'" required
                    :placeholder="$t('mais.form.createdByCPF.field')" />
                  <div class="invalid-feedback">
                    {{ formFieldStatusMessage.createdByCPF }}
                  </div>
                </div>
                <!-- NOME DO CADASTRADOR -->
                <div class="col-lg-12 col-xl-6 mb-3">
                  <label class="form-label required">
                    {{ $t("mais.form.createdByName.field") }}
                    <i class="material-icons" v-tooltip="tooltips.cadastradoPor">
                      info
                    </i>
                  </label>
                  <input v-model="maisOrg.createdByName" type="text" class="form-control"
                    :class="formFieldStatus.createdByName" required
                    :placeholder="$t('mais.form.createdByName.field')" />
                  <div class="invalid-feedback">
                    {{ formFieldStatusMessage.createdByName }}
                  </div>
                </div>
                <!-- EMAIL DO CADASTRADOR -->
                <div class="col-lg-12 col-xl-6 mb-3">
                  <label class="form-label required">
                    {{ $t("mais.form.createdByEmail.field") }}
                  </label>
                  <input v-model="maisOrg.createdByEmail" type="email" class="form-control"
                    :class="formFieldStatus.createdByEmail" required
                    :placeholder="$t('mais.form.createdByEmail.field')" />
                  <div class="invalid-feedback">
                    {{ formFieldStatusMessage.createdByEmail }}
                  </div>
                </div>
                <!-- RELACAO DO CADASTRADOR COM A OSC -->
                <div class="col-lg-12 col-xl-6 mb-3">
                  <label class="form-label required">
                    {{ $t("mais.form.createdByRelations.field") }}
                  </label>
                  <input v-model="maisOrg.createdByRelations" type="text" class="form-control"
                    :class="formFieldStatus.createdByRelations" required
                    :placeholder="$t('mais.form.createdByRelations.field')" />
                  <div class="invalid-feedback">
                    {{ formFieldStatusMessage.createdByRelations }}
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button :disabled="!tab.first_item_completed" @click.prevent="tab.current_tab = 2" class="btn-next">
                  Avançar
                </button>
              </div>
            </section>
            <!-- SEGUNDA ETAPA -->
            <section class="mt-3" v-if="tab.current_tab === 2">
              <div class="row mb-3">
                <!-- CNPJ -->
                <div class="col-lg-12 col-xl-4 mb-3">
                  <label class="form-label required">
                    {{ $t("mais.form.cnpj.field") }}
                  </label>
                  <input v-model="maisOrg.cnpj" type="text" class="form-control" :class="formFieldStatus.cnpj"
                    v-maska="'##.###.###/####-##'" required @change="onCNPJChange()"
                    :placeholder="$t('mais.form.cnpj.field')" />
                  <div class="invalid-feedback">
                    {{ formFieldStatusMessage.cnpj }}
                  </div>
                </div>

                <!-- EMAIL -->
                <div class="col-lg-12 col-xl-4 mb-3">
                  <label class="form-label required">
                    {{ $t("mais.form.email.field") }}
                  </label>
                  <input v-model="email_to_verify" type="email" class="form-control" :class="formFieldStatus.email"
                    @change="onChangeEmailToVerify()" required :placeholder="$t('mais.form.email.field')" />
                  <div class="invalid-feedback">
                    {{ formFieldStatusMessage.email }}
                  </div>
                </div>

                <!-- SENHA -->
                <div class="col-lg-12 col-xl-4 mb-3">
                  <label class="form-label required">
                    {{ $t("mais.form.password.field") }}
                    <i class="material-icons" v-tooltip="tooltips.senha">
                      info
                    </i>
                  </label>

                  <div class="input-group">
                    <input v-model="maisOrg.password" v-bind:type="visibility_password ? 'text' : 'password'"
                      class="form-control" :class="formFieldStatus.password" required
                      :placeholder="$t('mais.form.password.field')" @input="validateForm()" />
                    <div class="input-group-append d-flex align-items-center">
                      <i v-if="!visibility_password" class="material-icons"
                        @click="visibility_password = !visibility_password">
                        visibility
                      </i>
                      <i v-if="visibility_password" class="material-icons"
                        @click="visibility_password = !visibility_password">
                        visibility_off
                      </i>
                    </div>
                    <div class="invalid-feedback">
                      {{ formFieldStatusMessage.password }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 text-end" v-if="!existsMaisOrganization && need_verification_code">
                  <button :disabled="!tab.second_item_completed_partially" class="btn-save"
                    @click.prevent="saveMaisOrgCognito()">
                    Avançar
                  </button>
                </div>

                <div v-if="showForced" class="mt-2">
                  <p>
                    Para continuar o cadastro através de um e-mail diferente
                    da Receita é preciso ler e aceitar o termo de
                    responsabilidade abaixo:
                  </p>
                  <!-- ByPass  -->
                  <label class="label-checkbox mb-3" v-bind:class="{
                    'option-selected': maisOrg.forced,
                  }">
                    <input v-model="maisOrg.forced" type="checkbox" @change="forceEmail($event)" />
                    <i class="fa fa-square-o unchecked"></i>
                    <i class="fa fa-check-square checked"></i>
                    Eu declaro para os devidos fins que sou responsável e
                    juridicamente investido para representar a organização em
                    questão
                  </label>
                  <!-- Alerta Receita -->
                  <div class="alert alert-warning w-100" role="alert">
                    <p>
                      O E-mail informado não corresponde ao cadastrado na
                      Receita Federal.
                    </p>
                    <p>
                      Por favor, atualize-o
                      <a target="_blank"
                        href="https://www.gov.br/pt-br/servicos/inscrever-ou-atualizar-cadastro-nacional-de-pessoas-juridicas">
                        clicando aqui
                      </a>
                    </p>
                  </div>
                </div>

                <section v-if="tab.second_item_completed_partially">
                  <!-- VERIFICAÇÃO DE CÓDIGO -->
                  <div class="row" v-if="showVerificationCode">
                    <div class="col-8">
                      <label class="form-label required">
                        Código de Verificação
                      </label>
                      <p>
                        <small>
                          Código de verificação enviado para o e-mail acima.
                        </small>
                        <br>
                        <small>
                          Caso não receba o código de verificação entre em contato pelo Fale Conosco
                        </small>
                      </p>
                      <input v-model="verification_code" type="text" class="form-control"
                        :class="formFieldStatus.verification_code" required placeholder="Código de Verificação" />
                      <div class="invalid-feedback">
                        {{ formFieldStatusMessage.verification_code }}
                      </div>
                    </div>

                    <div class="col-4 align-self-end text-end">
                      <button :disabled="verification_code == ''" class="btn-save" @click.prevent="verificationCode()">
                        Confirmar
                      </button>
                    </div>
                  </div>

                  <div class="row" v-if="!need_verification_code">
                    <!-- NOME -->
                    <div class="col-lg-12 col-xl-7 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.name.field") }}
                      </label>
                      <input v-model="maisOrg.name" type="text" class="form-control" :class="formFieldStatus.name"
                        required :placeholder="$t('mais.form.name.field')" />
                      <div class="invalid-feedback">
                        {{ formFieldStatusMessage.name }}
                      </div>
                    </div>

                    <!-- NATUREZA JURÍDICA -->
                    <div class="col-lg-12 col-xl-5 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.legalNature.field") }}
                      </label>

                      <div class="alert alert-warning" role="alert" v-if="maisOrg.legalNature === '0'">
                        O CNPJ cadastrado não se enquadra como OSC. Em caso de
                        dúvidas, entre em contato conosco:
                        <a href="mailto:mapadoimpacto.social@gmail.com">mapadoimpacto.social@gmail.com</a>
                      </div>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.legalNature != '0',
                        invalid: maisOrg.legalNature == '0',
                      }" v-model="maisOrg.legalNature" :options="legalNatures" :settings="settingsSelect2Disabled">
                      </Select2>

                      <span v-if="maisOrg.legalNature == '0'" class="text-error">
                        {{ formFieldStatusMessage.legalNature }}
                      </span>
                    </div>

                    <!-- RESPONSÁVEL -->
                    <div class="col-lg-12 col-xl-7 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.ceo.field") }}
                        <i class="material-icons" v-tooltip="tooltips.responsavel">
                          info
                        </i>
                      </label>
                      <input v-model="maisOrg.ceo" type="text" class="form-control" :class="formFieldStatus.ceo"
                        required :placeholder="$t('mais.form.ceo.field')" />
                      <div class="invalid-feedback">
                        {{ formFieldStatusMessage.ceo }}
                      </div>
                    </div>

                    <!-- RAÇA/COR -->
                    <div class="col-lg-12 col-xl-5 mb-3">
                      <label class="form-label">
                        {{ $t("mais.form.breed.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.breed != '',
                      }" v-model="maisOrg.breed" :options="breeds" :settings="settingsSelect2">
                      </Select2>
                    </div>

                    <!-- FAIXA ETÁRIA -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label">
                        {{ $t("mais.form.ageGroup.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.ageGroup != '',
                      }" v-model="maisOrg.ageGroup" :options="ageGroups" :settings="settingsSelect2">
                      </Select2>
                    </div>

                    <!-- GENERO -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label">
                        {{ $t("mais.form.genre.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.genre != '',
                      }" v-model="maisOrg.genre" :options="genres" :settings="settingsSelect2">
                      </Select2>
                    </div>

                    <!-- PAIS-->
                    <div class="col-lg-12 col-xl-4 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.country.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.country != '',
                        invalid: maisOrg.country === '',
                      }" v-model="maisOrg.country" :settings="settingsSelect2" :options="countries_options">
                      </Select2>

                      <span v-if="maisOrg.country === ''" class="text-error">
                        {{ formFieldStatusMessage.country }}
                      </span>
                    </div>

                    <!-- ESTADO -->
                    <div class="col-lg-12 col-xl-4 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.state.field") }}
                      </label>

                      <Select2 :class="{
                        valid: maisOrg.state != '',
                        invalid: maisOrg.state === '',
                      }" v-model="maisOrg.state" :settings="settingsSelect2" :options="states" @change="loadCities()">
                      </Select2>
                      <span v-if="maisOrg.state === ''" class="text-error">
                        {{ formFieldStatusMessage.state }}
                      </span>
                    </div>

                    <!-- CIDADE -->
                    <div class="col-lg-12 col-xl-4 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.city.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.city != '',
                        invalid: maisOrg.city === '',
                      }" v-model="maisOrg.city" :options="cities" :settings="settingsSelect2">
                      </Select2>
                      <div v-if="maisOrg.city === ''" class="text-error">
                        {{ formFieldStatusMessage.city }}
                      </div>
                    </div>

                    <!-- ENDEREÇO -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.address.field") }}
                      </label>
                      <input v-model="maisOrg.address" type="text" class="form-control" :class="formFieldStatus.address"
                        required :placeholder="$t('mais.form.address.field')" />
                      <div class="invalid-feedback">
                        {{ formFieldStatusMessage.address }}
                      </div>
                    </div>

                    <!-- SETOR -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.sector.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.sector.length > 0,
                        invalid: maisOrg.sector.length === 0,
                      }" v-model="maisOrg.sector" :options="setor_atuacao" :settings="settingsSelect2Multiple">
                      </Select2>

                      <input v-if="maisOrg.sector.includes('23')" v-model="maisOrg.sectorOther" type="text"
                        class="form-control mt-3" :class="formFieldStatus.sectorOther" required
                        :placeholder="$t('mais.form.sector.field')" />

                      <span v-if="maisOrg.sector.length == 0" class="text-error">
                        {{ formFieldStatusMessage.sector }}
                      </span>
                    </div>

                    <!-- TELEFONE -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.telephone.field") }}
                      </label>
                      <input v-model="maisOrg.telephone" type="text" class="form-control"
                        :class="formFieldStatus.telephone" required :placeholder="$t('mais.form.telephone.field')" />
                      <div class="invalid-feedback">
                        {{ formFieldStatusMessage.telephone }}
                      </div>
                    </div>

                    <!-- SITE -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label">
                        {{ $t("mais.form.site.field") }}
                      </label>
                      <input v-model="maisOrg.site" type="text" class="form-control" :class="formFieldStatus.site"
                        :placeholder="$t('mais.form.site.field')" />
                      <div class="invalid-feedback">
                        {{ formFieldStatusMessage.site }}
                      </div>
                    </div>

                    <!-- QUANTIDADE DE MEMBROS -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label m-0 required">
                        {{ $t("mais.form.quantityEmployee.field") }}
                      </label>
                      <div class="form-text m-0 mb-2">ANO FISCAL ANTERIOR</div>
                      <input v-model="maisOrg.quantityEmployee" type="number" class="form-control"
                        :class="formFieldStatus.quantityEmployee" required
                        :placeholder="$t('mais.form.quantityEmployee.field')" />
                      <div class="invalid-feedback">
                        {{ formFieldStatusMessage.quantityEmployee }}
                      </div>
                    </div>

                    <!-- QUEM SAO OS BENEFICIÁRIOS -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label required">
                        {{ $t("mais.form.whoBeneficiaries.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.whoBeneficiaries.length > 0,
                      }" v-model="maisOrg.whoBeneficiaries" :options="whoBeneficiaries_options"
                        :settings="settingsSelect2">
                      </Select2>
                      <span v-if="maisOrg.whoBeneficiaries.length === 0" class="text-error">
                        {{ formFieldStatusMessage.whoBeneficiaries }}
                      </span>
                    </div>

                    <!-- SELO -->
                    <div class="col-lg-12 col-xl-6 mb-3">
                      <label class="form-label">
                        {{ $t("mais.form.seals.field") }}
                      </label>

                      <Select2 @select="validateForm()" :class="{
                        valid: maisOrg.seals.length > 0,
                      }" v-model="maisOrg.seals" :options="seals" :settings="settingsSelect2Multiple">
                      </Select2>
                    </div>

                    <!-- CERTIFICADO OU TITULO -->
                    <div class="col-12">
                      <div class="
                          d-flex
                          justify-content-between
                          align-items-center
                        ">
                        <h3>Certifados e Títulos</h3>
                        <a href="#certificateModal" v-tooltip="'Adicionar Certificado e/ou Título'"
                          style="width: 20px; height: 20px" class="
                            text-decoration-none
                            d-flex
                            justify-content-center
                            align-items-center
                            p-1
                            bg-primary
                            rounded-circle
                          ">
                          <i class="m-0 text-light opacity-100 material-icons">
                            add
                          </i>
                        </a>
                      </div>
                      <div class="w-100 border-bottom border-dark opacity-25 mb-3" style="height: 1px"></div>
                    </div>

                    <!-- LISTA DE CERTIFICADOS -->
                    <div class="flex-wrap flex-row gap-1 list-group mb-3">
                      <a v-for="(cert, index) in maisOrg.certificates" :key="cert" href="#" class="
                          list-group-item list-group-item-action
                          active
                          w-auto
                        " style="max-width: 200px" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 v-tooltip="cert.nameCert" class="mb-1 text-truncate">
                            {{ cert.nameCert }}
                          </h5>
                          <small @click.prevent="removeCert(index)">
                            <i class="text-danger material-icons opacity-100">delete</i>
                          </small>
                        </div>
                        <p class="mb-1">{{ cert.typeCert }}</p>
                        <small>{{ getCertDate(cert) }}</small>
                      </a>
                    </div>

                    <!-- MODAL DE CERTIFICADOS -->
                    <div id="certificateModal" class="modalDialog">
                      <div class="row">
                        <!-- CERTIFICADO OU TITULO -->
                        <div class="col-lg-6 col-xl-12 mb-3">
                          <label class="form-label required">
                            {{ $t("mais.form.typeCert.field") }}
                          </label>
                          <Select2 @select="validateForm()" :class="{
                            valid: maisOrg.typeCert != '',
                          }" v-model="maisOrg.typeCert" :options="typeCertOptions" :settings="settingsSelect2"
                            required>
                          </Select2>
                        </div>

                        <!--NOME DO TITULO OU CERTIFICADO -->
                        <div class="col-lg-6 col-xl-12 mb-3">
                          <label class="form-label required">
                            {{ $t("mais.form.nameCert.field") }}
                          </label>
                          <input v-model="maisOrg.nameCert" type="text" class="form-control"
                            :class="formFieldStatus.nameCert" :placeholder="$t('mais.form.nameCert.field')" required />
                        </div>

                        <!-- INICIO DA VALIDADE -->
                        <div class="col-xl-6 col-md-12 mb-3">
                          <label class="form-label">
                            {{ $t("mais.form.initialDateCert.field") }}
                          </label>
                          <input v-model="maisOrg.initialDateCert" type="date" class="form-control"
                            :class="formFieldStatus.initialDateCert" required />
                        </div>

                        <!-- FIM DE VALIDADE -->
                        <div class="col-xl-6 col-md-12 mb-3">
                          <label class="form-label">
                            {{ $t("mais.form.endDateCert.field") }}
                          </label>
                          <input v-model="maisOrg.endDateCert" type="date" class="form-control"
                            :class="formFieldStatus.endDateCert" required />
                        </div>

                        <div class="
                            mt-3
                            d-flex
                            justify-content-between
                            align-items-center
                          ">
                          <a ref="close" href="#close" title="Close" class="close">
                            <button type="button" class="btn btn-fale-conosco">
                              Voltar
                            </button>
                          </a>
                          <button :disabled="!canAddCert" type="button" @click.prevent="addCert()"
                            class="text-capitalize btn-save">
                            Adicionar
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- QUADRO DE DIRIGENTES -->
                    <div class="col-12 mb-3">
                      <label class="form-label">
                        {{ $t("mais.form.boardDirectors.field") }}
                      </label>
                      <ckeditor :editor="editor" v-model="maisOrg.boardDirectors" :config="editorConfig">
                      </ckeditor>
                    </div>

                    <!-- TOTAL DE RECURSOS -->
                    <div class="col-xl-3 col-lg-4 col-md-12 mb-3">
                      <label class="form-label m-0">
                        {{ $t("mais.form.resourceTotals.field") }}
                      </label>
                      <div class="form-text m-0 mb-2">ANO FISCAL ANTERIOR</div>
                      <currency-input v-model="maisOrg.resourceTotals" :options="{ currency: 'BRL' }" />
                    </div>

                    <!-- FONTES DE RECURSO -->
                    <div class="col-xl-9 col-lg-8 col-md-12 mb-3">
                      <label class="form-label">
                        {{ $t("mais.form.resourceFronts.field") }}
                      </label>
                      <chip :options="{ maxLength: 150 }" v-model:chips="maisOrg.resourceFronts" />
                    </div>

                    <!-- CONSENTIMENTO -->
                    <div class="col-12">
                      <div class="form-check">
                        <input v-model="consent" class="form-check-input" type="checkbox" />
                        <label class="form-check-label">
                          <a href="#termsModal">{{
                              $t("login.create.term")
                          }}</a>
                        </label>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div id="only_createdByCPF" v-if="tab.show_update_only_createdByCPF" class="mt-3 mb-3 alert alert-danger"
                role="alert">
                <p>
                  Essa OSC já teve seus dados atualizados. Caso deseje uma nova
                  atualização, ela deve ser feita pelo solicitante anterior
                </p>
              </div>

              <div v-if="!need_verification_code" class="mt-3 mb-3" style="text-align: right">
                <button :disabled="!formIsValid" type="button" @click.prevent="saveOSC()" class="btn-save">
                  {{ $t("mais.form.btn.save") }}
                </button>
              </div>
            </section>
          </div>
          <div class="footer d-flex pb-3 align-items-center justify-content-evenly">
            <powered-by></powered-by>
          </div>
          <div id="print" v-show="false">
            <img :src="getImageBaseUrl('mais-confirmacao.jpg')" />
          </div>
        </form>
      </div>
    </div>
  </div>
  <img class="w-100" src="./../images/barra_ods.svg" alt="Barra" />

  <div id="termsModal" class="modalDialog">
    <div>
      <h2>{{ $t("login.create.use_term") }}</h2>
      <div style="max-height: 300px; overflow-y: auto">
        <term></term>
      </div>
      <div class="modalDialog-btns">
        <a href="#close" title="Close" class="close">
          <button type="button" class="legacy-modal-button-back">
            {{ $t("login.create.button_close") }}
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { cloneDeep } from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Select2 from "vue3-select2-component";
import AuthService from "../../services/auth/auth";
import CNPJService from "../../services/cnpj-consulta/cnpj.service.js";
import SeallBasicLambdasService from "../../services/seall-basic-lambdas/seall-basic-lambdas.service";
import UniversalCountryService from "../../services/universal-country/universal-country.service";
import Validators from "../../utils/Validators";
import Chip from "./Chip";
import CurrencyInput from "./CurrencyInput";
import PoweredBy from "./PoweredBy.vue";
import Term from "./Term.vue";

export default {
  name: "mais",
  data() {
    return {
      editor: ClassicEditor,
      visibility_password: false,
      org_exists: null,
      email_receita: null,
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      countries_options: [],
      whoBeneficiaries_options: [
        { id: "Pessoas", text: "Pessoas" },
        { id: "Organizações", text: "Organizações" },
        { id: "Ambos", text: "Ambos" },
        { id: "Outros", text: "Outros" },
      ],
      countries: [],
      cities: [],
      states: [],
      tooltips: {
        responsavel: "Responsável legal da OSC",
        cadastradoPor: "Responsável pelo cadastro da OSC",
        areaAtuacao: "",
        informacaoSolicitante:
          "Nesta área deve ser fornecido os dados do responsável pelo cadastro da OSC na plataforma",
        informacaoOSC: "Nesta área, preencher com dados reais da OSC",
        senha: 'Se este é o seu primeiro acesso, neste campo você deve CRIAR UMA SENHA, que deverá ser usada nos próximos acessos.'
      },
      genres: [
        {
          id: "Homem cisgênero",
          text: "Homem cisgênero - pessoa que foi designada como homem ao nascer e se identifica como homem",
        },
        {
          id: "Homem transgênero",
          text: "Homem transgênero - pessoa que foi designada como mulher ao nascer e se identifica como homem",
        },
        {
          id: "Mulher cisgênero",
          text: "Mulher cisgênero - pessoa que foi designada como mulher ao nascer e se identifica como mulher",
        },
        {
          id: "Mulher transgênero",
          text: "Mulher transgênero - pessoa que foi designada como homem ao nascer e se identifica como mulher",
        },
        {
          id: "Não binário ou gênero fluido",
          text: "Não binário ou gênero fluido - pessoa que não se identifica nem como homem nem como mulher",
        },
      ],
      ageGroups: [
        "Menos de 18 anos",
        "Entre 18 e 25 anos",
        "Entre 26 e 35 anos",
        "Entre 36 e 45 anos",
        "Entre 46 e 55 anos",
        "Entre 56 e 65 anos",
        "Mais de 65 anos",
      ],
      seals: [
        "Ambev Voa",
        "Melhores ONGs",
        "Selo Phomenta",
        "Instituto Phi",
        "Selo Doar",
        "Vitrine das ongs",
        "Tecnologia Social Banco do Brasil",
        "Prêmio Itaú Social",
        "Socialab",
        "Empreendedor Folha de São Paulo",
      ],
      legalNatures: [
        { id: "306-9", text: "Fundação Privada" },
        { id: "206-2", text: "Sociedade Empresária Limitada " },
        { id: "322-0", text: "Organização Religiosa" },
        { id: "330-1", text: "Organização Social" },
        { id: "399-9", text: "Associação Privada" },
        { id: "0", text: "Outros" },
      ],
      breeds: ["Amarela", "Branca", "Negra: preta", "Negra: parda", "Indígena"],
      email_to_verify: "",
      loading: false,
      typeCertOptions: [
        { id: "Título", text: "Título" },
        { id: "Certificado", text: "Certificado" },
      ],
      maisOrg: {
        cnpj: "",
        name: "",
        country: "",
        state: "",
        city: "",
        ceo: "",
        telephone: "",
        sector: [],
        sectorOther: "",
        site: "",
        quantityEmployee: "",
        whoBeneficiaries: "",
        email: null,
        password: "",
        address: "",
        createdByName: "",
        createdByCPF: "",
        createdByEmail: "",
        createdByRelations: "",
        ageGroup: "",
        genre: "",
        breed: "",
        legalNature: "0",
        seals: [],
        certificates: [],
        typeCert: "",
        nameCert: "",
        initialDateCert: "",
        endDateCert: "",
        boardDirectors: "",
        resourceFronts: [],
        resourceTotals: "",
        forced: false,
      },
      formFieldStatus: {
        cnpj: "",
        name: "",
        country: "",
        state: "",
        city: "",
        ceo: "",
        telephone: "",
        sector: "",
        sectorOther: "",
        site: "",
        quantityEmployee: "",
        whoBeneficiaries: "",
        email: "",
        password: "",
        address: "",
        createdByName: "",
        createdByCPF: "",
        createdByEmail: "",
        createdByRelations: "",
        ageGroup: "",
        genre: "",
        breed: "",
        legalNature: "",
        seals: "",
        typeCert: "",
        nameCert: "",
        initialDateCert: "",
        endDateCert: "",
        boardDirectors: "",
        resourceFronts: "",
        resourceTotals: "",
      },
      formFieldStatusMessage: {
        cnpj: "",
        name: "",
        country: "",
        state: "",
        city: "",
        ceo: "",
        telephone: "",
        sector: "",
        sectorOther: "",
        site: "",
        quantityEmployee: "",
        whoBeneficiaries: "",
        email: "",
        password: "",
        createdByName: "",
        createdByCPF: "",
        createdByEmail: "",
        createdByRelations: "",
        address: "",
        ageGroup: "",
        genre: "",
        breed: "",
        legalNature: "",
        seals: "",
        typeCert: "",
        nameCert: "",
        initialDateCert: "",
        endDateCert: "",
        boardDirectors: "",
        resourceFronts: "",
        resourceTotals: "",
      },
      formIsValid: false,
      steps: {
        headers: [
          { title: "Pré Cadastro", stepName: "step-1" },
          { title: "Cadastro OSC", stepName: "step-2" },
        ],
        prevText: "Anterior",
        nextText: "Próximo",
      },
      tab: {
        items: [
          { id: 1, title: "Pré Cadastro" },
          { id: 2, title: "Cadastrar OSC" },
        ],
        current_tab: 1,
        first_item_completed: false,
        second_item_completed_partially: false,
        show_receita_msg: false,
        show_update_only_createdByCPF: false,
      },
      setor_atuacao: [
        { id: 1, text: "AGROPECUÁRIA" },
        { id: 2, text: "ALIMENTAÇÃO" },
        { id: 3, text: "ANIMAIS DOMÉSTICOS" },
        { id: 4, text: "ARTE E CULTURA" },
        { id: 5, text: "ASSISTÊNCIA SOCIAL" },
        { id: 6, text: "ATIVIDADES ADMINISTRATIVAS" },
        { id: 7, text: "ATIVIDADES FINANCEIRAS" },
        { id: 8, text: "BIODIVERSIDADE " },
        { id: 9, text: "CIÊNCIA E PESQUISA" },
        { id: 10, text: "COMBATE À VIOLÊNCIA" },
        { id: 11, text: "COMÉRCIO" },
        { id: 12, text: "CONSTRUÇÃO CIVIL" },
        { id: 13, text: "DIREITOS HUMANOS" },
        { id: 14, text: "EDUCAÇÃO INFANTIL" },
        { id: 15, text: "EDUCAÇÃO PROFISSIONALIZANTE" },
        { id: 16, text: "ENERGIA" },
        { id: 17, text: "ESPORTE E RECREAÇÃO" },
        { id: 18, text: "EXTRATIVISMO" },
        { id: 19, text: "GESTÃO DE RESÍDUOS" },
        { id: 20, text: "INFORMAÇÃO E COMUNICAÇÃO" },
        { id: 21, text: "INTERNACIONALIZAÇÃO" },
        { id: 22, text: "MORADIA" },
        { id: 23, text: "OUTRO" },
        { id: 24, text: "PESCA E AQÜICULTURA" },
        { id: 25, text: "PRODUÇÃO FLORESTAL" },
        { id: 26, text: "SANEAMENTO" },
        { id: 27, text: "SAÚDE HUMANA" },
        { id: 28, text: "TRANSFORMAÇÃO DE MATERIAIS" },
        { id: 29, text: "TRANSPORTE E ARMAZENAGEM" },
      ],
      settingsSelect2: {
        width: "resolve",
        language: "pt-BR",
      },
      settingsSelect2Disabled: {
        width: "resolve",
        language: "pt-BR",
        disabled: true,
      },
      settingsSelect2Multiple: {
        width: "resolve",
        language: "pt-BR",
        multiple: true,
      },
      openDialogVerificationCode: false,
      consent: false,
      need_verification_code: false,
      showVerificationCode: false,
      verification_code: null,
      existsMaisOrganization: false,
      cnpjPasswordEmail: false,
      baseUrl: "",
      showForced: false,
    };
  },
  computed: {
    canAddCert() {
      return (
        this.maisOrg.nameCert != "" &&
        this.maisOrg.typeCert != "" &&
        this.maisOrg.initialDateCert != "" &&
        this.maisOrg.endDateCert != ""
      );
    },
  },
  mounted() {
    this.$i18n.locale = "pt";
    this.baseUrl = process.env.VUE_APP_FRONTEND;

    if (process.env.VUE_APP_ENVIRONMENT == "local") {
      this.fillFormLocal();
      this.validateForm();
      this.loadInfoReceita();
    }
  },
  methods: {
    fillFormLocal() {
      this.maisOrg.createdByCPF = "02518713204";
      this.maisOrg.createdByName = "Gabriel";
      this.maisOrg.createdByEmail = "gb2013lp@gmail.com";
      this.maisOrg.createdByRelations = "Director";
      this.maisOrg.cnpj = "44544852000115";
      this.maisOrg.email = "gabriel_castr@outlook.com.br";
      this.email_to_verify = "gabriel_castr@outlook.com.br";
      this.maisOrg.password = "Ab12345678*";
    },
    loadInfoReceita() {
      this.loading = true;
      const cnpj = this.maisOrg.cnpj.replace(/\D+/g, "").replace("/", "");
      if (cnpj === "") {
        return;
      }

      CNPJService.getCNPJ(cnpj)
        .done((response) => {
          if ("status" in response && response["status"] == "ERROR") {
            this.$notify({
              title: this.$t("mais.form.cnpj.field_error_msg"),
              type: "warn",
            });
          }
          if ("uf" in response) {
            this.maisOrg.state = Validators.getEstadosBrasileirosPelaSigla(
              response["uf"]
            );
          }

          if ("municipio" in response) {
            this.maisOrg.city = response["municipio"]
              .toLowerCase()
              .split(" ")
              .map((word) => {
                return word[0].toUpperCase() + word.substring(1);
              })
              .join(" ");
          }
          if ("nome" in response) {
            this.maisOrg.name = response["nome"];
          }
          if ("fantasia" in response && response["fantasia"] != "") {
            this.maisOrg.name = response["fantasia"];
          }
          if ("email" in response) {
            this.maisOrg.email = response["email"];
            this.email_receita = response["email"];
          }
          if ("telefone" in response) {
            this.maisOrg.telephone = `${response["telefone"]}`;
          }

          if (
            "logradouro" in response &&
            "numero" in response &&
            "bairro" in response &&
            "uf" in response &&
            "municipio" in response &&
            "cep" in response
          ) {
            this.maisOrg.address = `${response["logradouro"]}, nº:${response["numero"]}, ${response["bairro"]}, ${response["municipio"]} -  ${response["uf"]} ${response["cep"]}`;
          }

          if ("natureza_juridica" in response) {
            const natureza_juridica =
              response["natureza_juridica"].split(" - ")[0];

            this.maisOrg.legalNature =
              this.legalNatures.find(
                (legalNature) => legalNature.id === natureza_juridica
              ) != null
                ? natureza_juridica
                : "0";
          }

          this.checkMaisOrganizationExists();

          this.validateForm();
          this.loadCountries();
        })
        .fail(() => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
          });
        });
    },
    checkMaisOrganizationExists() {
      const cnpj = this.maisOrg.cnpj.replace(/\D+/g, "").replace("/", "");
      AuthService.getMaisOrganizationByCNPJ(cnpj)
        .then((response) => {
          if (response.data.cnpj == cnpj) {
            this.existsMaisOrganization = true;
            this.need_verification_code = false;
            this.org_exists = response.data;
          }
        })
        .catch(() => {
          this.existsMaisOrganization = false;
          this.need_verification_code = true;
        });
    },
    saveMaisOrgCognito() {
      const maisOrg = cloneDeep(this.maisOrg);
      maisOrg.cnpj = maisOrg.cnpj.replace(/\D+/g, "").replace("/", "");
      maisOrg.sector = maisOrg.sector.join("|");

      this.validatePassowrd();
      if (this.formFieldStatus.password === 'is-invalid') return;

      this.loading = true;
      AuthService.maisOrganization(maisOrg, "step-1")
        .then(() => {
          this.loading = false;
          this.showVerificationCode = true;
          this.showForced = false;

          this.openDialogVerificationCode = true;
        })
        .catch((error) => {
          this.loading = false;
          if (
            "msg" in error.response.data &&
            error.response.data.msg == "User already exists" &&
            this.need_verification_code
          ) {
            this.showVerificationCode = false;
            this.need_verification_code = false;
            this.showForced = false;
          } else {
            this.$notify({
              title: this.$t("common.error_http"),
              type: "error",
            });
          }
        });
    },
    verificationCode(openDialog = true) {

      const code = {
        email: this.maisOrg.email,
        confirmationCode: this.verification_code,
      };

      AuthService.verificationCode(code)
        .then((code) => {
          this.need_verification_code = false;
          this.showVerificationCode = false;
        })
        .catch((error) => {
          this.need_verification_code = true;
          this.$notify({
            title: "Código de Verificação Inválido",
            type: "error",
          });
        });
    },
    loadCountries() {

      if (this.countries.length > 0) {
        this.loading = false;
        return;
      }

      UniversalCountryService.getAll()
        .then(async (response) => {
          this.countries = response.data.body.filter(
            (c) => c.name === "Brazil"
          );
          this.countries_options = this.countries.map((c) => {
            return { id: c.name, text: c.name };
          });
          this.loading = false;
          this.maisOrg.country = "Brazil";
          this.validateForm();
          this.loadStates();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadStates() {
      this.states = this.countries
        .find((c) => c.name === this.maisOrg.country)
        .states.map((state) => state.name);

      if (this.maisOrg.state != "") {
        this.loadCities();
      }
    },
    loadCities() {
      this.cities = this.countries
        .find((c) => c.name === this.maisOrg.country)
        .states.find((state) => this.maisOrg.state === state.name).cities;
    },
    validateForm() {
      const cnpj = Validators.validateIsNotEmpty(this.maisOrg.cnpj);
      this.formFieldStatus.cnpj = Validators.isValidOrInvalid(cnpj);
      this.formFieldStatusMessage.cnpj = Validators.setMessageError(
        cnpj,
        this.$t("mais.form.cnpj.field_error_msg")
      );

      this.maisOrg.name = this.maisOrg.name.trim();
      const name = Validators.validateIsNotEmpty(this.maisOrg.name);
      this.formFieldStatus.name = Validators.isValidOrInvalid(name);
      this.formFieldStatusMessage.name = Validators.setMessageError(
        name,
        this.$t("mais.form.common.required_field")
      );

      let email =
        Validators.validateEmail(this.email_to_verify) &&
        this.maisOrg.email &&
        this.maisOrg.email == this.email_to_verify;

      email = this.maisOrg.email != this.email_receita ? this.maisOrg.forced && email : email;

      this.existsMaisOrganization;
      this.formFieldStatus.email = Validators.isValidOrInvalid(email);
      this.formFieldStatusMessage.email = Validators.setMessageError(
        email,
        this.$t("login.main.email_invalid")
      );

      const password = Validators.validateCognitoPassword(
        this.maisOrg.password
      );
      this.formFieldStatus.password = Validators.isValidOrInvalid(password);
      this.formFieldStatusMessage.password = Validators.setMessageError(
        password,
        this.$t("login.main.password_invalid")
      );

      const telephone = Validators.validateIsNotEmpty(this.maisOrg.telephone);
      this.formFieldStatus.telephone = Validators.isValidOrInvalid(telephone);
      this.formFieldStatusMessage.telephone = Validators.setMessageError(
        telephone,
        this.$t("mais.form.common.required_field")
      );

      const country = Validators.validateIsNotEmpty(this.maisOrg.country);
      this.formFieldStatus.country = Validators.isValidOrInvalid(country);
      this.formFieldStatusMessage.country = Validators.setMessageError(
        country,
        this.$t("mais.form.common.required_field")
      );

      const city = Validators.validateIsNotEmpty(this.maisOrg.city);
      this.formFieldStatus.city = Validators.isValidOrInvalid(city);
      this.formFieldStatusMessage.city = Validators.setMessageError(
        city,
        this.$t("mais.form.common.required_field")
      );

      const state = Validators.validateIsNotEmpty(this.maisOrg.state);
      this.formFieldStatus.state = Validators.isValidOrInvalid(state);
      this.formFieldStatusMessage.state = Validators.setMessageError(
        state,
        this.$t("mais.form.common.required_field")
      );

      this.maisOrg.ceo = this.maisOrg.ceo.trim();
      const ceo = Validators.validateName(this.maisOrg.ceo);
      this.formFieldStatus.ceo = Validators.isValidOrInvalid(ceo);
      this.formFieldStatusMessage.ceo = Validators.setMessageError(
        ceo,
        this.$t("mais.form.ceo.field_error_msg")
      );

      const sector = this.maisOrg.sector.length > 0;
      this.formFieldStatus.sector = Validators.isValidOrInvalid(sector);
      this.formFieldStatusMessage.sector = Validators.setMessageError(
        sector,
        this.$t("mais.form.common.required_field")
      );

      const quantityEmployee = this.maisOrg.quantityEmployee > 0;
      this.formFieldStatus.quantityEmployee =
        Validators.isValidOrInvalid(quantityEmployee);
      this.formFieldStatusMessage.quantityEmployee = Validators.setMessageError(
        quantityEmployee,
        this.$t("mais.form.common.required_field")
      );

      const whoBeneficiaries = Validators.validateIsNotEmpty(this.maisOrg.whoBeneficiaries);
      this.formFieldStatus.whoBeneficiaries = Validators.isValidOrInvalid(whoBeneficiaries);
      this.formFieldStatusMessage.whoBeneficiaries = Validators.setMessageError(
        whoBeneficiaries,
        this.$t("mais.form.common.required_field")
      );

      this.maisOrg.createdByName = this.maisOrg.createdByName.trim();
      const createdByName = Validators.validateName(this.maisOrg.createdByName);
      this.formFieldStatus.createdByName =
        Validators.isValidOrInvalid(createdByName);
      this.formFieldStatusMessage.createdByName = Validators.setMessageError(
        createdByName,
        this.$t("mais.form.createdByName.field_error_msg")
      );

      const createdByCPF = Validators.validateCPF(this.maisOrg.createdByCPF);
      this.formFieldStatus.createdByCPF =
        Validators.isValidOrInvalid(createdByCPF);
      this.formFieldStatusMessage.createdByCPF = Validators.setMessageError(
        createdByCPF,
        this.$t("mais.form.createdByCPF.field_error_msg")
      );

      const createdByEmail = Validators.validateEmail(
        this.maisOrg.createdByEmail
      );
      this.formFieldStatus.createdByEmail =
        Validators.isValidOrInvalid(createdByEmail);
      this.formFieldStatusMessage.createdByEmail = Validators.setMessageError(
        createdByEmail,
        this.$t("mais.form.createdByEmail.field_error_msg")
      );

      const createdByRelations = Validators.validateIsNotEmpty(
        this.maisOrg.createdByRelations
      );
      this.formFieldStatus.createdByRelations =
        Validators.isValidOrInvalid(createdByRelations);
      this.formFieldStatusMessage.createdByRelations =
        Validators.setMessageError(
          createdByRelations,
          this.$t("mais.form.common.required_field")
        );

      const legalNature =
        Validators.validateIsNotEmpty(this.maisOrg.legalNature) &&
        this.maisOrg.legalNature != "0";
      this.formFieldStatus.legalNature =
        Validators.isValidOrInvalid(legalNature);
      this.formFieldStatusMessage.legalNature = Validators.setMessageError(
        legalNature,
        this.$t("mais.form.common.required_field")
      );

      this.tab.first_item_completed =
        createdByName && createdByCPF && createdByEmail && createdByRelations;

      this.tab.second_item_completed_partially = email && cnpj;

      this.cnpjPasswordEmail = email && cnpj && password;

      this.onChangeEmailToVerify();
      const requiredFieldsIsInvalid = Object.keys(this.formFieldStatus).filter(
        (key) => this.formFieldStatus[key] === "is-invalid"
      )
      this.formIsValid = requiredFieldsIsInvalid.length === 0 && this.consent;
    },
    saveOSC() {
      this.validateForm();
      if (!this.formIsValid) return;

      const maisOrg = cloneDeep(this.maisOrg);
      maisOrg.cnpj = maisOrg.cnpj.replace(/\D+/g, "").replace("/", "");
      maisOrg.sector = maisOrg.sector.join("|");

      maisOrg.resourceFronts = maisOrg.resourceFronts.map((f) => f.value);

      if (
        this.existsMaisOrganization &&
        this.org_exists.email != this.maisOrg.email
      ) {
        this.$notify({
          title: "OSC Já Cadastrada!",
          type: "error",
        });
      }

      this.loading = true;

      AuthService.maisOrganization(maisOrg, "step-2")
        .then(() => {
          this.sendEmail();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.msg === "mais.error_backend.already_exists") {
            this.$notify({
              title: this.$t(error.response.data.msg),
              type: "error",
            });
          } else if (
            error.response.data.msg ===
            "mais.error_backend.show_update_only_createdByCPF"
          ) {
            this.tab.show_update_only_createdByCPF = true;
          } else {
            this.$notify({
              title: this.$t("common.error_http"),
              type: "error",
            });
          }
        });
    },
    onCNPJChange() {
      this.loadInfoReceita();
    },
    onChangeEmailToVerify() {
      this.tab.show_receita_msg =
        this.email_to_verify != "" &&
        (this.maisOrg.email != null) &&
        (this.email_receita != this.email_to_verify);

      this.showForced = this.tab.show_receita_msg && !this.existsMaisOrganization;

      if(!this.tab.show_receita_msg){
        // Tudo correto altero o email
        this.maisOrg.email = this.email_to_verify;
      }
    },
    getImageBaseUrl(url) {
      return process.env.VUE_APP_FRONTEND_S3_ASSETS + "email/" + url;
    },
    forceEmail() {
      this.maisOrg.email = this.email_to_verify;
      this.onChangeEmailToVerify();
    },
    sendEmail() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      const html = `<!DOCTYPE html>
                        <html>
                            <head></head>
                            <body>
                                ${prtHtml}
                            </body>
                        </html>`;

      const email = this.maisOrg.email;

      const emails = {
        emailTo: email,
        htmlBody: html,
        subject: "MAIS - Confirmação de Cadastro ou Atualização",
      };

      const payload = { emails };

      this.loading = true;
      SeallBasicLambdasService.sendEmail(payload)
        .then(() => {
          AuthService.logout();
          const user = {
            username: this.maisOrg.email,
            password: this.maisOrg.password,
          };
          this.$store.dispatch("auth/login", user).then(() => {
            if (AuthService.validateOrganizationCompletedRadar()) {
              window.location = "/map";
            } else {
              window.location = "/organization";
            }
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
          });
        });
    },
    validatePassowrd() {
      const password = Validators.validateCognitoPassword(
        this.maisOrg.password
      );
      this.formFieldStatus.password = Validators.isValidOrInvalid(password);
      this.formFieldStatusMessage.password = Validators.setMessageError(
        password,
        this.$t("login.main.password_invalid")
      );
    },
    addCert() {
      if (!this.canAddCert) return;

      this.maisOrg.certificates.push({
        nameCert: this.maisOrg.nameCert,
        typeCert: this.maisOrg.typeCert,
        initialDateCert: this.maisOrg.initialDateCert,
        endDateCert: this.maisOrg.endDateCert,
      });

      this.maisOrg.nameCert = "";
      this.maisOrg.typeCert = "";
      this.maisOrg.initialDateCert = "";
      this.maisOrg.endDateCert = "";

      this.$refs.close.click();
    },
    removeCert(index) {
      this.maisOrg.certificates.splice(index, 1);
    },
    getCertDate(cert) {
      const init = cert.initialDateCert.split("-");
      const end = cert.endDateCert.split("-");

      return `${init[2]}/${init[1]}/${init[0]} à ${end[2]}/${end[1]}/${end[0]}`;
    },
  },
  components: {
    Loading,
    Select2,
    Term,
    PoweredBy,
    CurrencyInput,
    Chip,
  },
};
</script>

<style lang="scss">
@import "../css/bootstrap/bootstrap.min.css";

@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}

@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

.g-dialog.wrapper {
  color: #000;

  .content {
    padding: 20px;
  }

  .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px lightgray;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #492e85;
  border-radius: 5px;
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
  background-color: #c4e0b2;
  overflow: hidden;
}

.form-text {
  font-size: 12px;

  &:after {
    content: "*";
    margin-left: 5px;
    color: #00b9bd;
  }
}

.form-label {
  display: inline-flex;
  align-items: center;
}

.organization {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 33%;
  background-image: url("./../images/background-create.png");
  background-repeat: repeat;
  background-size: cover;
}

.main {
  background-color: #c4e0b2;
  height: 98vh;
  max-height: 98vh;
}

.tab-active {
  color: #1d14ff;
  font-weight: bold;
}

.box {
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  min-height: 500px;
  width: 70%;

  h2 {
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    background-color: #1d14ff;
    margin: 1rem 0;
    padding: 1rem 0;
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .logos {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    text-align: center;
    flex-wrap: wrap;

    .img {
      &>img {
        max-width: 150px;
        width: 50%;
        padding: 1rem;

        &:first-child {
          border-right: 1px solid #ccc;
        }
      }
    }

    .duvidas {
      display: flex;
      align-items: center;
    }
  }

  .form {
    min-height: 50vh;
    max-height: 50vh;
    padding: 2rem 5rem;
    width: 100%;
    overflow-y: auto;

    .form-label {
      font-family: "Roboto", sans-serif;

      &.required:after {
        content: "*";
        margin-left: 5px;
        color: #00b9bd;
      }
    }
  }

  .btn-save {
    background-color: #1d14ff;
    border-radius: 4px;
    color: #ffffff;
    font-family: NunitoSans-Regular;
    border-style: hidden;
    padding: 0.7rem;
    text-transform: uppercase;
  }

  .btn-next {
    background-color: #1d14ff;
    border-radius: 4px;
    color: #ffffff;
    font-family: NunitoSans-Regular;
    border-style: hidden;
    padding: 0.7rem;
  }

  .btn-fale-conosco {
    border-color: #1d14ff;
    border-radius: 4px;
    color: #1d14ff;
    font-family: NunitoSans-Regular;
    padding: 0.7rem;
    text-decoration: none;
  }
}

.modalDialog {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}

.modalDialog>div {
  width: 40vw;
  margin: 10vh 30vw;
  position: relative;
  padding: 1vh 3vw;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}

.modalDialog img {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.modalDialog h2 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 133%;
  text-align: center;
  color: #1d14ff;
  margin: 0vh 0vw;
  padding-bottom: 5vh;
}

.modalDialog h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 133%;
  text-align: center;
  color: #9d9d9c;
  margin: 0vh 0vw;
  padding-bottom: 2vh;
}

.modalDialog p {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  text-align: center;
  color: #9d9d9c;
  margin: 0vh 0vw;
  padding-bottom: 0vh;
}

.modalDialog-btns {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 5vh 0vw 0vh 0vw;
}

.legacy-modal-button-back {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 8px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 1vw;
  padding: 1vh 2vw;
  cursor: pointer;
}

.select2-container {
  z-index: 99;
}

@media (max-width: 1365px) {
  .organization {
    width: 100% !important;
  }

  .image {
    display: none;
  }

  .box {
    .form {
      padding: 1rem 2rem;
    }
  }

  .modalDialog>div {
    width: 95vw;
    margin: 10vh 2.5vw;
  }
}

.label-checkbox {
  cursor: pointer;
  color: #a3a3a3;
  display: flex;
  gap: 1rem;
  text-align: justify;
  align-items: center;

  &.option-selected {
    font-weight: 500;
    color: #1d14ff;
  }

  &:hover input~.checkmark {
    background-color: #ccc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      background-color: #1d14ff;
    }

    &:checked~.checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 25%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  i {
    font-size: 1.2rem !important;
  }

  input[type="checkbox"],
  .checked {
    display: none;
  }

  input[type="checkbox"]:checked~.checked {
    display: inline-block;
  }

  input[type="checkbox"]:checked~.unchecked {
    display: none;
  }
}
</style>