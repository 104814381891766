<template>
  <div class="powered-by">
    Powered by
    <a :href="baseUrl" target="blank">
      <!-- <img src="../images/logo-seall.png" /> -->
      Seall Intelligence
    </a>
  </div>
</template>
<script>
export default {
  name: "PoweredBy",
  data() {
    return {
      baseUrl: "",
    };
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_FRONTEND;
  },
};
</script>
<style>
.powered-by {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  color: #492e85;
}

.powered-by a {
  cursor: pointer;
  color: rgb(108, 117, 125);
  text-decoration: none;
}
</style>