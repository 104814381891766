<template>
  <div class="chip-container">
    <div class="chips">
      <div class="chip" v-for="(chip, i) of chips" :key="chip.label">
        <span class="chip-text">{{ chip.value }}</span>
        <i class="material-icons" @click="editChip(i)">edit</i>
        <i class="material-icons" @click="deleteChip(i)">clear</i>
      </div>
    </div>
    <input
      class="form-control"
      v-bind:class="{ 'mt-1': chips.length > 0 }"
      v-model="currentInput.value"
      :maxlength="options.maxLength"
      @keypress.enter="saveChip"
      @keydown.delete="backspaceDelete"
      @blur="saveChip"
    />
  </div>
</template>

<script>
export default {
  name: "chip",
  props: {
    set: {
      type: Boolean,
      default: true,
    },
    chips: [],
    options: {
      maxLength: 99999999,
    },
  },
  emits: ["update:chips"],
  data() {
    return {
      currentInput: {
        value: "",
      },
    };
  },
  methods: {
    saveChip() {
      const { chips, currentInput, set } = this;
      if (
        ((set &&
          chips.indexOf(currentInput) === -1 &&
          currentInput.value != "") ||
          !set) &&
        chips.push(currentInput)
      ) {
        this.currentInput = {
          value: "",
        };
        this.$emit("change", this.chips);
      }
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
      this.$emit("change", this.chips);
    },
    editChip(index) {
      this.currentInput = this.chips[index];
      this.chips.splice(index, 1);
    },
    backspaceDelete({ which }) {
      if (
        which == 8 &&
        this.currentInput.value == "" &&
        this.chips.splice(this.chips.length - 1)
      ) {
        this.$emit("change", this.chips);
      }
    },
  },
};
</script>

<style>
.chip-container {
  width: 100%;
  border: 0px;
  min-height: 34px;
  display: flex;
  align-content: space-between;
  flex-direction: column;
}
.chip {
  margin: 2px;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #2f1764;
  border-radius: 4px;
  display: flex;
  font-family: Nunito-Regular;
  font-style: normal;
  font-size: 14px;
  align-items: center;
  width: max-content;
}
.chip-text {
  max-width: 300px;
  overflow-wrap: break-word;
}
i {
  cursor: pointer;
  opacity: 0.56;
  margin-left: 8px;
  font-size: 18px !important;
}
input {
  flex: 1 1 auto;
  width: 30px;
  border: none;
  outline: none;
  padding: 4px;
  border: 1px solid #2f1764;
  border-radius: 4px;
}
</style>