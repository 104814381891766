import axios from 'axios';

class UniversalCountryService {
    http = axios.create({
        baseURL: 'https://7ozlctsjki.execute-api.us-east-2.amazonaws.com/production',
        headers: {
            Accept: 'application/json'
        }
    });

    token = '';

    getAll() {
        return this.http.get('');
    }
}

export default new UniversalCountryService();
